<template>
    <div>
        <vx-card class="filter-card pr-3">

            <vs-row vs-w="12">
                <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" class="desc-border-box no-padd">
                    <p>
                        USP Alumni Meet<span>{{ count_leads }}</span>
                    </p>
                </vs-col>
            </vs-row>
            <vs-row>
                <vs-col vs-w="8.9" vs-offset="0.1" class="mt-2">
                    <vs-row class="mb-5 mt-6">
                        <vs-col vs-w="1.1" vs-offset="0.1" vs-type="flex" vs-justify="flex-start" vs-align="center" style="width: 8.167%;">
                            <h6 class="ele-side-head filter">Filters</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2" class="alumni-drop"
                            id="city" style="width: 17.667%;">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                                    City
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                                    <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity"
                                        :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2" class="alumni-drop"
                            id="gm" style="width: 17.667%;">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox"
                                    style="text-align: left;">
                                    GM
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="initSelectedGM" :multiple="true" valueFormat="object"
                                        :value-consists-of="'LEAF_PRIORITY'" :options="treeDataGM" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2" class="alumni-drop"
                            id="spoc" style="width: 17.667%;">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                                    SPOC
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="initSelectedSpoc" :value-consists-of="'LEAF_PRIORITY'"
                                        valueFormat="object" :multiple="true" :options="treeDataSpoc" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2" class="alumni-drop"
                            id="lvl" style="width: 17.667%;">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox"
                                    style="text-align: left;">
                                    Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="selected_level" :options="treeDataLevels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2" class="alumni-drop"
                            id="macc_level_drop" v-if="selectedLevels != null && selectedLevels.toString() == 'm_level'" style="width: 19.667%;">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox"
                                    style="text-align: left;">
                                    CM Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="initSelectedCMLevel" :multiple="true" :options="treeDataCMLevel"
                                        :value-consists-of="'LEAF_PRIORITY'" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" vs-align="center" vs-w="2" class="alumni-drop"
                            id="macc_level_drop" v-if="selectedLevels != null && selectedLevels.toString() == 'u_level'" style="width: 19.667%;">
                            <vs-dropdown vs-custom-content vs-trigger-click>
                                <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                                    USP Level
                                    <i class="material-icons">expand_more</i>
                                </a>
                                <vs-dropdown-menu class="customDropDown">
                                    <treeselect v-model="init_selected_iiml_levels" :multiple="true"
                                        :value-consists-of="'LEAF_PRIORITY'" :options="macc_levels" />
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </vs-col>
                        <vs-col vs-w="1.1" vs-offset="0.1" vs-type="flex" vs-justify="flex-start" vs-align="center" style="width: 8.167%;">
                            <h6 class="date-side-head filter">Date Filters</h6>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" class="none_padding mt-12" vs-align="center"
                            vs-w="2.5" vs-offset="0.3">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Last Call</label>
                                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                                        firstDay: 1,
                                        format: 'DD-MMM-YYYY',
                                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                                    :ranges="ranges" :timePicker24Hour="true" :showWeekNumbers="false"
                                    :showDropdowns="false" :autoApply="false" v-model="lastCallDateRange" class="visitor">
                                </date-range-picker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" class="none_padding mt-12" vs-align="center"
                            vs-offset="0.2" vs-w="2.5">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Next Call</label>
                                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                                        firstDay: 1,
                                        format: 'DD-MMM-YYYY',
                                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                                    :ranges="next_call_date_ranges" :timePicker24Hour="true" :showWeekNumbers="false"
                                    :showDropdowns="false" :autoApply="false" v-model="nextCallDateRange" class="visitor">
                                </date-range-picker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" class="none_padding mt-12" vs-align="center"
                            vs-offset="0.2" vs-w="2.5">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Enrollment Date Range</label>
                                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                                        firstDay: 1,
                                        format: 'DD-MMM-YYYY',
                                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                                    :ranges="next_call_date_ranges" :timePicker24Hour="true" :showWeekNumbers="false"
                                    :showDropdowns="false" :autoApply="false" v-model="enrollmentDateRange" class="visitor">
                                </date-range-picker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="space-around" class="none_padding mt-12" vs-align="center"
                        vs-offset="1.4" vs-w="2.5">
                            <div style="display: inline-grid; width: 100%; margin-top: -13px">
                                <label class="date_filter_heading">Scheduled Date Range</label>
                                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                                        firstDay: 1,
                                        format: 'DD-MMM-YYYY',
                                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                                    :ranges="next_call_date_ranges" :timePicker24Hour="true" :showWeekNumbers="false"
                                    :showDropdowns="false" :autoApply="false" v-model="scheduledDateRange" class="visitor">
                                </date-range-picker>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7"
                            style="margin-top: 40px;">
                            <div>
                                <vs-button color="#0044ba" icon="clear" @click="clearDateFilter" size="small"></vs-button>
                            </div>
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col vs-w="9">
                            <div class="meet_search_criteria">
                                <div style="display:flex; justify-content: space-between; align-items: center;"
                                    class="mt-2 mx-3">
                                    <h6 class="search_heading ml-2 mt-2">Search Criteria:</h6>
                                    <div style="display: flex;">
                                        <vs-button color="#0044BA" icon="search" size="small"
                                            @click="getSpocIds()"></vs-button>&nbsp;
                                            <vs-button color="#0044BA" icon="refresh" size="small"
                                            @click="refreshdata()"></vs-button>
                                    </div>
                                </div>
                                <div style="display: inline-block; width: 100%; margin-left:10px; padding: 10px;">
                                    <span v-for="chip in selected_city" :key="chip.id">
                                        <vs-chip class="meet_criteria_chip"
                                            style="color:#000000; background: rgb(234, 229, 250)"
                                            @click="removechip(chip, 'city')" closable>
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_gm" :key="chip.id">
                                        <vs-chip class="meet_criteria_chip"
                                            style="color:#000000; background: rgb(202,236,197)"
                                            @click="removechip(chip, 'gm')" closable>
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_spoc" :key="chip.id">
                                        <vs-chip class="meet_criteria_chip"
                                            style="color:#000000; background: rgb(209,241,252)"
                                            @click="removechip(chip, 'spoc')" closable>
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-if="selectedLevels != null">
                                        <vs-chip class="meet_criteria_chip" closable
                                            @click="removechip(selectedLevels.toString(), 'level')"
                                            style="color:#000000; background: rgb(250,225,254)">
                                            <b v-if="selectedLevels.toString() == 'u_level'">USP Level</b>
                                            <b v-else>CM Level</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selected_cm" :key="chip.id">
                                        <vs-chip class="meet_criteria_chip"
                                            style="color:#000000; background: rgb(255,213,213)"
                                            @click="removechip(chip, 'cm_level')" closable>
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                    <span v-for="chip in selectedMAccLevels" :key="chip.id">
                                        <vs-chip class="meet_criteria_chip"
                                            style="color:#000000; background: rgb(255,213,213)"
                                            @click="removechip(chip, 'Mlevel')" closable>
                                            <b>{{ chip }}</b>
                                        </vs-chip>
                                    </span>
                                </div>
                            </div>
                        </vs-col>
                    </vs-row>
                </vs-col>
                <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="flex-start" style="margin-top: -1.5%; margin-bottom: 1%;">
                    <vx-card class="usp-options_filter">
                        <p class="usp-option-side mb-4 mt-4">Options</p>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Can-ID" v-model="identity" class="usp-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" @click="getSpocIds()" icon="search"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Candidate Name" v-model="candidate_name" class="usp-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="getSpocIds()"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Contact Number" v-model="candidate_number"
                                    class="usp-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="getSpocIds()"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-3">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Email Address" v-model="email" class="usp-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="getSpocIds()"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                                <vs-input placeholder="Experience" v-model="experience" class="usp-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="getSpocIds()"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                                <vs-input placeholder="Designation" v-model="designation" class="usp-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="getSpocIds()"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mb-4">
                            <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                                <vs-input placeholder="Company" v-model="company" class="usp-input-filter" />
                                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF"
                                    type="gradient" icon="search" @click="getSpocIds()"></vs-button>
                            </vs-col>
                        </vs-row>
                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="center" class="mb-4">
                                <vs-button color="#0044BA" icon="search" size="small"
                                    @click="getSpocIds()">Search</vs-button>
                            </vs-col>
                        </vs-row>
                    </vx-card>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card class="filter-card" style="margin:2% 0 2% 0;padding-bottom:2%">
            <vs-row v-if="alumni_spocs.length != 0" vs-w="12">
                <vs-col vs-w="12" class="no-padd">
                    <vs-row>
                        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
                            <p style="text-align:center">
                                Alumni SPOC Wise
                            </p>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="center" vs-justify="center" class="table-overflow">
                            <table class="rwd-table">
                                <thead>
                                    <tr>
                                        <th>Alumni</th>
                                        <th>Total</th>
                                        <th v-for="level in alumni_levels" :key="level">{{ level }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in alumni_spocs" :key="item.spoc">
                                        <!-- <td data-column="First Name">James</td> -->
                                        <td>
                                            {{ item.hr_name }}
                                        </td>

                                        <td>
                                            <div style="color:#0044BA" v-if="item.total != 0"
                                                @click="getCandidateTableByHr(item, 'total')">{{ item.total }}</div>
                                            <div style="color:#0044BA" v-else>0</div>
                                        </td>
                                        <td v-for="level in alumni_levels" :key="level">
                                            <div style="color:#0044BA" v-if="item.levels[0][level] != 0"
                                                @click="getCandidateTableByHr(item, level)">{{ item.levels[0][level] }}
                                            </div>
                                            <div style="color:#0044BA" v-else>0</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
            <div v-else class="no-data-found">
                No Data Found
            </div>
        </vx-card>
        <vx-card class="filter-card" style="margin:2% 0 2% 0;padding-bottom:2%">
            <vs-row v-if="city_tier_wise.length != 0" vs-w="12">
                <vs-col vs-w="12" class="no-padd">
                    <vs-row>
                        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
                            <p style="text-align:center">
                                City Tier Wise
                            </p>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="center" vs-justify="center" class="table-overflow">
                            <table class="rwd-table">
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>City Tier</th>
                                        <th>GM</th>
                                        <th>Total</th>
                                        <th v-for="level in city_wise_levels" :key="level">{{ level }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in city_tier_wise" :key="item.spoc">
                                        <!-- <td data-column="First Name">James</td> -->

                                        <td>
                                            {{ item.city }}
                                        </td>

                                        <td>
                                            {{ item.city_tier }}
                                        </td>

                                        <td>
                                            {{ item.gm_name }}
                                        </td>

                                        <td>
                                            <div style="color:#0044BA" @click="getSpocStats(item.gm_id, 'total')">{{
                                                item.total }}
                                            </div>
                                        </td>
                                        <td v-for="level in city_wise_levels" :key="level">
                                            <div style="color:#0044BA" @click="getSpocStats(item.gm_id, level)">{{
                                                item.levels[0][level] }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
            <div v-else class="no-data-found">
                No Data Found
            </div>
        </vx-card>
        <vx-card class="filter-card" v-if="spoc_tier_wise.length != 0" style="margin:2% 0 2% 0;padding-bottom:2%">
            <vs-row vs-w="12">
                <vs-col vs-w="12" class="no-padd">
                    <vs-row>
                        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="desc-border-box2 no-padd mb-8">
                            <p style="text-align:center">
                                Stats
                            </p>
                        </vs-col>
                        <vs-col vs-w="12" vs-type="center" vs-justify="center" class="table-overflow">
                            <table class="rwd-table">
                                <thead>
                                    <tr>
                                        <th>SPOC</th>
                                        <th>Level</th>
                                        <!-- <th>Total</th> -->
                                        <th v-for="level in spoc_wise_levels" :key="level">{{ level }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in spoc_tier_wise" :key="item.spoc">
                                        <!-- <td data-column="First Name">James</td> -->

                                        <td>
                                            {{ item.spoc_name }}
                                        </td>

                                        <td>
                                            {{ item.city_tier }}
                                        </td>

                                        <!-- <td>
                                            <div style="color:#0044BA" v-if="item.total != 0"
                                                @click="getCandidateTableBySpoc(item, 'total')">{{
                                                    item.total }}
                                            </div>
                                            <div style="color:#0044BA" v-else>{{ item.total }}</div>
                                        </td> -->
                                        <td v-for="level in spoc_wise_levels" :key="level">
                                            <div style="color:#0044BA" @click="getCandidateTableBySpoc(item, level)"
                                                v-if="item.total != 0">{{
                                                    item.levels[0][level] }}
                                            </div>
                                            <div style="color:#0044BA" v-else>{{ item.levels[0][level] }}</div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </vs-col>
                    </vs-row>
                </vs-col>
            </vs-row>
            <!-- <div v-else class="no-data-found">
                No Data Found
            </div> -->
        </vx-card>
        <div v-if="table_data_showing && candidate_details.length != 0" style="margin:2% 0 2% 0;padding-bottom:2%">
            <div class="mt-3">
                <alumni-meet-tabulator :alumni_table_details="candidate_details"></alumni-meet-tabulator>
            </div>
            <vs-row style="margin-top: 3%">
                <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
                    <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
                </vs-col>
            </vs-row>
        </div>

    </div>
</template>
  
<script>
import axios from "axios";
import constants from "../../constants.json";
import moment from 'moment';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Datepicker from "vuejs-datepicker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
// import EventBus from "../components/eventbus.js";
import AlumniMeetTabulator from './AlumniMeetTabulator.vue';

export default {
    components: {
        Treeselect,
        AlumniMeetTabulator,
        DateRangePicker,
        Datepicker
    },
    data() {
        return {
            lastCallDateRange: {
                startDate: null,
                endDate: null,
            },
            nextCallDateRange: {
                startDate: null,
                endDate: null,
            },
            enrollmentDateRange: {
                startDate: null,
                endDate: null,
            },
            scheduledDateRange: {
                startDate: null,
                endDate: null,
            },
            spoc_tabulator: false,
            candidate_details: [],
            tablelinks: 0,
            currentTablePage: 1,
            city_tier_wise: [],
            city_wise_levels: [],
            spoc_tier_wise: [],
            spoc_wise_levels: [],
            alumni_levels: [],
            alumni_spocs: [],
            initSelectedCMLevel: [],
            init_selected_iiml_levels: [],
            selectedMAccLevels: [],
            selected_level: null,
            selectedLevels: null,
            treeDataLevels: [
                { id: "m_level", label: "CM Level" },
                { id: "u_level", label: "USP Level" }
            ],
            treeDataCMLevel: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        {
                            id: "P1",
                            label: "P1",
                            children: [
                                {
                                    id: "M3",
                                    label: "M3",
                                },
                                {
                                    id: "M3+",
                                    label: "M3+",
                                },
                                {
                                    id: "M3++",
                                    label: "M3++",
                                },
                                {
                                    id: "M5",
                                    label: "M5",
                                },
                                {
                                    id: "M6",
                                    label: "M6",
                                },
                                {
                                    id: "M6 AR",
                                    label: "M6 AR",
                                },
                                {
                                    id: "M6 AR-",
                                    label: "M6 AR-",
                                },
                            ],
                        },
                        {
                            id: "P2",
                            label: "P2",
                            children: [
                                {
                                    id: "M4",
                                    label: "M4",
                                },
                                {
                                    id: "M2",
                                    label: "M2",
                                },
                            ],
                        },
                        {
                            id: "P3",
                            label: "P3",
                            children: [
                                {
                                    id: "M4-",
                                    label: "M4-",
                                },
                                {
                                    id: "M1",
                                    label: "M1",
                                },
                            ],
                        },
                        {
                            id: "ENROLLED",
                            label: "ENROLLED",
                            children: [
                                {
                                    id: "M7",
                                    label: "M7",
                                },
                                {
                                    id: "M7-",
                                    label: "M7-",
                                },
                                {
                                    id: "M7+",
                                    label: "M7+",
                                },
                                {
                                    id: "M7#",
                                    label: "M7#",
                                },
                                {
                                    id: "M7-JJ",
                                    label: "M7-JJ",
                                },
                                {
                                    id: "M7X",
                                    label: "M7X",
                                },
                                {
                                    id: "M7D1",
                                    label: "M7D1",
                                },
                                {
                                    id: "M7D2",
                                    label: "M7D2",
                                },
                                {
                                    id: "M8",
                                    label: "M8",
                                },
                                {
                                    id: "M8+",
                                    label: "M8+",
                                },
                                {
                                    id: "M8-",
                                    label: "M8-",
                                },
                                {
                                    id: "M9",
                                    label: "M9",
                                },
                                {
                                    id: "M9-",
                                    label: "M9-",
                                },
                                {
                                    id: "M10",
                                    label: "M10",
                                },
                                {
                                    id: "M7-IR",
                                    label: "M7-IR",
                                },
                                {
                                    id: "M7-INT",
                                    label: "M7-INT",
                                },
                            ]
                        },
                        {
                            id: "Dnd",
                            label: "Dnd",
                            children: [
                                {
                                    id: "N/A",
                                    label: "N/A",
                                },
                                {
                                    id: "DND",
                                    label: "DND",
                                },
                            ]
                        }
                    ],
                },
            ],
            macc_levels: [
                {
                    id: "All",
                    label: "All",
                    children: [
                        // { id: "blank", label: "No U Level" },
                        { id: "U0--", label: "U0--" },
                        { id: "U0", label: "U0" },
                        { id: "U1", label: "U1" },
                        { id: "U1+", label: "U1+" },
                        { id: "U2", label: "U2" },
                        { id: "U3-", label: "U3-" },
                        { id: "U3", label: "U3" },
                        { id: "U3+", label: "U3+" },
                        { id: "U3++", label: "U3++"},
                        { id: "U4", label: "U4" },
                        { id: "U5", label: "U5" },
                        { id: "U6", label: "U6" },
                        { id: "U7-", label: "U7-" },
                        { id: "U7", label: "U7" },
                        { id: "U7R", label: "U7R" },
                        { id: "U7+", label: "U7+" },
                        { id: "U8", label: "U8" },
                        { id: "U8+", label: "U8+" },
                        { id: "U9-", label: "U9-" },
                        { id: "U9", label: "U9" },
                        { id: "U9+", label: "U9+" },
                        { id: "U10-", label: "U10-" },
                        { id: "U10", label: "U10" },
                        { id: "U11", label: "U11" },
                        { id: "U11+", label: "U11+" },
                        { id: "U12", label: "U12" },
                    ]
                }
            ],
            initSelectedSpoc: [],
            treeDataSpoc: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            initSelectedGM: [],
            treeDataGM: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            initSelectedCity: [],
            treeDataCity: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            count_leads: 0,
            selected_city: [],
            selected_cm: [],
            selected_spoc: [],
            selected_gm: [],
            user_with_gms: [],
            filter_spoc_ids: [],
            selected_from_date: null,
            selected_to_date: null,
            clicked_item: {},
            clicked_level: '',
            table_data_showing: false,
            gm_ids: [],
            default_selected_city: [],
            default_selected_gm: [],
            default_selected_spoc: [],
            loggedin_team: "",
            logged_in_user_id: "",
            identity: "",
            email: "",
            candidate_number: "",
            company: "",
            candidate_name: "",
            experience: "",
            designation: "",
        };
    },
    mounted() {
        this.selected_level = ['u_level'];
        this.getUsersWithGMs();
    },
    watch: {
        selected_level: function () {
            this.initSelectedCMLevel = [];
            this.init_selected_iiml_levels = [];
            this.mergeAllSearch()
        },
        initSelectedCity: function (val) {
            if (val.length != 0) {
                this.mergeAllSearch();
            } else {
                let team = localStorage.getItem('team')
                if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                    this.initSelectedCity = this.default_selected_city
                }
            }
            if (val.length == 0) {
                this.selected_city = [];
            }
        },
        initSelectedGM: function (val) {
            if (val.length != 0) {
                this.mergeAllSearch();
            } else {
                let team = localStorage.getItem('team')
                if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                    this.initSelectedGM = this.default_selected_gm
                }
            }

            if (val.length == 0) {
                this.selected_gm = [];
            }
        },
        initSelectedSpoc: function (val) {
            if (val.length != 0) {
                this.mergeAllSearch();
            }
            else {
                let team = localStorage.getItem('team')
                if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                    this.initSelectedSpoc = this.default_selected_spoc
                }
            }
            if (val.length == 0) {
                this.selected_spoc = [];
            }
        },
        initSelectedCMLevel: function () {
            this.mergeAllSearch();
        },
        init_selected_iiml_levels: function () {
            this.mergeAllSearch();
        },
        currentTablePage: function () {
            if (this.spoc_tabulator) {
                this.getCandidateTableBySpoc(this.clicked_item, this.clicked_level)
            } else {
                this.getCandidateTableByHr(this.clicked_item, this.clicked_level)
            }
        },
    },
    methods: {
        convertToCustomFormat(date) {
            let new_date = moment(date).format("YYYY-MM-DD");
            return new_date;
        },
        getAlumniSpocs() {
            this.$vs.loading();
            this.getCityWiseStats()
            console.log(this.filter_spoc_ids);
            let url = `${constants.MILES_GOOGLE_INVITE}alumniHrCandidateCount`;
            console.log('lastCallDateRange,nextCallDateRange,enrollmentDateRange,DateRange')
            let obj = {
                identity: this.identity,
                email: this.email,
                mobile: this.candidate_number,
                name: this.candidate_name,
                company: this.company,
                experience: this.experience,
                designation: this.designation,
                spoc_ids: this.filter_spoc_ids,
                level_type: this.selected_level.toString(),
                m_levels: this.selected_cm.toString(),
                u_levels: this.selectedMAccLevels.join(),
                last_call_from_date: "",
                last_call_to_date: "",
                next_call_from_date: "",
                next_call_to_date: "",
                enrollment_from_date: "",
                enrollment_to_date: "",
                from_date: "",
                to_date: "",
            }
            if (
                this.lastCallDateRange.startDate !== null &&
                this.lastCallDateRange.endDate !== null
            ) {
                obj.last_call_from_date = this.convertToCustomFormat(this.lastCallDateRange.startDate);
                obj.last_call_to_date = this.convertToCustomFormat(this.lastCallDateRange.endDate);
            }
            if (
                this.nextCallDateRange.startDate !== null &&
                this.nextCallDateRange.endDate !== null
            ) {
                obj.next_call_from_date = this.convertToCustomFormat(this.nextCallDateRange.startDate);
                obj.next_call_to_date = this.convertToCustomFormat(this.nextCallDateRange.endDate);
            }
            if (
                this.enrollmentDateRange.startDate !== null &&
                this.enrollmentDateRange.endDate !== null
            ) {
                obj.enrollment_from_date = this.convertToCustomFormat(this.enrollmentDateRange.startDate);
                obj.enrollment_to_date = this.convertToCustomFormat(this.enrollmentDateRange.endDate);
            } 
            if (
                this.scheduledDateRange.startDate !== null &&
                this.scheduledDateRange.endDate !== null
            ) {
                obj.from_date = this.convertToCustomFormat(this.scheduledDateRange.startDate);
                obj.to_date = this.convertToCustomFormat(this.scheduledDateRange.endDate);
            }
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    if (response.data[0].levels != undefined || response.data[0].levels != []) {

                        let lvls = []
                        response.data[0].levels.forEach(level => {
                            lvls.push(Object.keys(level).toString())
                        });
                        response.data.forEach(spoc => {
                            const newspoc = spoc.levels.reduce((acc, cur) => Object.assign(acc, cur), {});
                            spoc.levels = [newspoc]
                        });
                        this.alumni_spocs = response.data
                        let count = [];
                        for (let j = 0; j < this.alumni_spocs.length; j++) {
                            const ele = this.alumni_spocs[j];
                            count.push(ele.total);
                        }
                        this.count_leads = count.reduce((partialSum, a) => partialSum + a, 0);
                        this.alumni_levels = [...new Set(lvls)];
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        getCityWiseStats() {
            this.spoc_tier_wise = [];
            this.$vs.loading();
            let url = `${constants.MILES_GOOGLE_INVITE}getHrCandidateGMWiseCount`;
            let obj = {
                identity: this.identity,
                email: this.email,
                mobile: this.candidate_number,
                name: this.candidate_name,
                company: this.company,
                experience: this.experience,
                designation: this.designation,
                spoc_ids: this.filter_spoc_ids,
                level_type: this.selected_level.toString(),
                m_levels: this.selected_cm.toString(),
                u_levels: this.selectedMAccLevels.join(),
                last_call_from_date: "",
                last_call_to_date: "",
                next_call_from_date: "",
                next_call_to_date: "",
                enrollment_from_date_date: "",
                enrollment_to_date: "",
                from_date: "",
                to_date: "",
            }
            if (
                this.lastCallDateRange.startDate !== null &&
                this.lastCallDateRange.endDate !== null
            ) {
                obj.last_call_from_date = this.convertToCustomFormat(this.lastCallDateRange.startDate);
                obj.last_call_to_date = this.convertToCustomFormat(this.lastCallDateRange.endDate);
            }
            if (
                this.nextCallDateRange.startDate !== null &&
                this.nextCallDateRange.endDate !== null
            ) {
                obj.next_call_from_date = this.convertToCustomFormat(this.nextCallDateRange.startDate);
                obj.next_call_to_date = this.convertToCustomFormat(this.nextCallDateRange.endDate);
            }
            if (
                this.enrollmentDateRange.startDate !== null &&
                this.enrollmentDateRange.endDate !== null
            ) {
                obj.enrollment_from_date = this.convertToCustomFormat(this.enrollmentDateRange.startDate);
                obj.enrollment_to_date = this.convertToCustomFormat(this.enrollmentDateRange.endDate);
            }
            if (
                this.scheduledDateRange.startDate !== null &&
                this.scheduledDateRange.endDate !== null
            ) {
                obj.from_date = this.convertToCustomFormat(this.scheduledDateRange.startDate);
                obj.to_date = this.convertToCustomFormat(this.scheduledDateRange.endDate);
            }
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    if (response.data[0].levels != undefined || response.data[0].levels != []) {

                        let lvls = []
                        response.data[0].levels.forEach(level => {
                            lvls.push(Object.keys(level).toString())
                        });
                        console.log(lvls);
                        response.data.forEach(spoc => {
                            const newspoc = spoc.levels.reduce((acc, cur) => Object.assign(acc, cur), {});
                            spoc.levels = [newspoc]
                        });
                        this.city_tier_wise = response.data
                        this.city_wise_levels = [...new Set(lvls)];
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        getSpocStats(id, lvl) {
            this.count_leads = 0;
            this.spoc_tier_wise = [];
            this.$vs.loading();
            this.table_data_showing = false;
            let url = `${constants.MILES_GOOGLE_INVITE}getHrCandidateSpocWiseCount`;
            let obj = {
                identity: this.identity,
                email: this.email,
                mobile: this.candidate_number,
                name: this.candidate_name,
                company: this.company,
                experience: this.experience,
                designation: this.designation,
                gm_id: id,
                levels: lvl,
                spoc_ids: this.filter_spoc_ids,
                level_type: this.selected_level.toString(),
                m_levels: "",
                u_levels: "",
                last_call_from_date: "",
                last_call_to_date: "",
                next_call_from_date: "",
                next_call_to_date: "",
                enrollment_from_date: "",
                enrollment_to_date: "",
                from_date: "",
                to_date: "",
            }
            if (lvl == 'total') {
                obj.m_levels = this.selected_cm.toString(),
                    obj.u_levels = this.selectedMAccLevels.join()
            }
            if (
                this.lastCallDateRange.startDate !== null &&
                this.lastCallDateRange.endDate !== null
            ) {
                obj.last_call_from_date = this.convertToCustomFormat(this.lastCallDateRange.startDate);
                obj.last_call_to_date = this.convertToCustomFormat(this.lastCallDateRange.endDate);
            }
            if (
                this.nextCallDateRange.startDate !== null &&
                this.nextCallDateRange.endDate !== null
            ) {
                obj.next_call_from_date = this.convertToCustomFormat(this.nextCallDateRange.startDate);
                obj.next_call_to_date = this.convertToCustomFormat(this.nextCallDateRange.endDate);
            }
            if (
                this.enrollmentDateRange.startDate !== null &&
                this.enrollmentDateRange.endDate !== null
            ) {
                obj.enrollment_from_date = this.convertToCustomFormat(this.enrollmentDateRange.startDate);
                obj.enrollment_to_date = this.convertToCustomFormat(this.enrollmentDateRange.endDate);
            }
            if (
                this.scheduledDateRange.startDate !== null &&
                this.scheduledDateRange.endDate !== null
            ) {
                obj.from_date = this.convertToCustomFormat(this.scheduledDateRange.startDate);
                obj.to_date = this.convertToCustomFormat(this.scheduledDateRange.endDate);
            }
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    if (response.data[0].levels != undefined || response.data[0].levels != []) {

                        let lvls = []
                        response.data[0].levels.forEach(level => {
                            lvls.push(Object.keys(level).toString())
                        });
                        console.log(lvls);
                        response.data.forEach(spoc => {
                            const newspoc = spoc.levels.reduce((acc, cur) => Object.assign(acc, cur), {});
                            spoc.levels = [newspoc]
                        });
                        this.spoc_tier_wise = response.data
                        this.spoc_wise_levels = [...new Set(lvls)];
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    // this.handleError(error);
                    console.log("error", error)
                });
        },
        getCandidateTableBySpoc(item, lvl) {
            this.$vs.loading();
            this.spoc_tabulator = true
            if (!this.spoc_tabulator) {
                this.currentTablePage = 1
            }
            this.clicked_item = item
            this.clicked_level = lvl
            let url = `${constants.MILES_GOOGLE_INVITE}getGmWiseSpocCandidateDetails?page=${this.currentTablePage}`;
            let obj = {
                identity: this.identity,
                email: this.email,
                mobile: this.candidate_number,
                name: this.candidate_name,
                company: this.company,
                experience: this.experience,
                designation: this.designation,
                gm_id: item.gm_id,
                spoc_id: item.spoc_id,
                level: lvl,
                level_type: this.selected_level.toString(),
                // m_levels: this.selected_cm.toString(),
                // u_levels: this.selectedMAccLevels.join(),
                last_call_from_date: "",
                last_call_to_date: "",
                next_call_from_date: "",
                next_call_to_date: "",
                enrollment_from_date: "",
                enrollment_to_date: "",
                from_date: "",
                to_date: "",
            }
            if (
                this.lastCallDateRange.startDate !== null &&
                this.lastCallDateRange.endDate !== null
            ) {
                obj.last_call_from_date = this.convertToCustomFormat(this.lastCallDateRange.startDate);
                obj.last_call_to_date = this.convertToCustomFormat(this.lastCallDateRange.endDate);
            }
            if (
                this.nextCallDateRange.startDate !== null &&
                this.nextCallDateRange.endDate !== null
            ) {
                obj.next_call_from_date = this.convertToCustomFormat(this.nextCallDateRange.startDate);
                obj.next_call_to_date = this.convertToCustomFormat(this.nextCallDateRange.endDate);
            }
            if (
                this.enrollmentDateRange.startDate !== null &&
                this.enrollmentDateRange.endDate !== null
            ) {
                obj.enrollment_from_date = this.convertToCustomFormat(this.enrollmentDateRange.startDate);
                obj.enrollment_to_date = this.convertToCustomFormat(this.enrollmentDateRange.endDate);
            }
            if (
                this.scheduledDateRange.startDate !== null &&
                this.scheduledDateRange.endDate !== null
            ) {
                obj.from_date = this.convertToCustomFormat(this.scheduledDateRange.startDate);
                obj.to_date = this.convertToCustomFormat(this.scheduledDateRange.endDate);
            }
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.candidate_details = [];
                    this.candidate_details = response.data.data
                    this.tablelinks = response.data.last_page;
                    this.count_leads = response.data.total;
                    this.currentTablePage = response.data.current_page;
                    this.table_data_showing = true;
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        getCandidateTableByHr(item, lvl) {
            this.spoc_tier_wise = [];
            this.$vs.loading();
            this.spoc_tabulator = false
            if (this.spoc_tabulator) {
                this.currentTablePage = 1
            }
            this.clicked_item = item
            this.clicked_level = lvl
            let url = `${constants.MILES_GOOGLE_INVITE}getHrCandidateDetails?page=${this.currentTablePage}`;
            let obj = {
                identity: this.identity,
                email: this.email,
                mobile: this.candidate_number,
                name: this.candidate_name,
                company: this.company,
                experience: this.experience,
                designation: this.designation,
                hr_name: item.hr_name,
                level: lvl,
                spoc_ids: this.filter_spoc_ids,
                level_type: this.selected_level.toString(),
                m_levels: "",
                u_levels: "",
                last_call_from_date: "",
                last_call_to_date: "",
                next_call_from_date: "",
                next_call_to_date: "",
                enrollment_from_date: "",
                enrollment_to_date: "",
                from_date: "",
                to_date: "",
            }
            if (lvl == 'total') {
                obj.m_levels = this.selected_cm.toString(),
                    obj.u_levels = this.selectedMAccLevels.join()
            }
            if (
                this.lastCallDateRange.startDate !== null &&
                this.lastCallDateRange.endDate !== null
            ) {
                obj.last_call_from_date = this.convertToCustomFormat(this.lastCallDateRange.startDate);
                obj.last_call_to_date = this.convertToCustomFormat(this.lastCallDateRange.endDate);
            }
            if (
                this.nextCallDateRange.startDate !== null &&
                this.nextCallDateRange.endDate !== null
            ) {
                obj.next_call_from_date = this.convertToCustomFormat(this.nextCallDateRange.startDate);
                obj.next_call_to_date = this.convertToCustomFormat(this.nextCallDateRange.endDate);
            }
            if (
                this.enrollmentDateRange.startDate !== null &&
                this.enrollmentDateRange.endDate !== null
            ) {
                obj.enrollment_from_date = this.convertToCustomFormat(this.enrollmentDateRange.startDate);
                obj.enrollment_to_date = this.convertToCustomFormat(this.enrollmentDateRange.endDate);
            }
            if (
                this.scheduledDateRange.startDate !== null &&
                this.scheduledDateRange.endDate !== null
            ) {
                obj.from_date = this.convertToCustomFormat(this.scheduledDateRange.startDate);
                obj.to_date = this.convertToCustomFormat(this.scheduledDateRange.endDate);
            }
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    this.candidate_details = [];
                    this.candidate_details = response.data.data
                    this.tablelinks = response.data.last_page;
                    this.count_leads = response.data.total;
                    this.currentTablePage = response.data.current_page;
                    this.table_data_showing = true;
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
        getUsersWithGMs() {
            let url = `${constants.SERVER_API}getUsersWithGMs`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    let logged_in_user = localStorage.getItem('user_id')
                    let team = localStorage.getItem('team')
                    if (team == 'CM' || team == 'SR' || team == 'ExEd') {
                        response.data.forEach(ele => {
                            if (logged_in_user == ele.id) {
                                this.user_with_gms = []
                                this.user_with_gms.push(ele);
                                this.getDropdownData();
                            }
                        });
                    } else if (localStorage.getItem('team') == 'GM') {
                        this.getGmReportingSpocs(response.data)
                    } else {
                        this.user_with_gms = response.data
                        this.getDropdownData();
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getGmReportingSpocs(res) {
            let gm_id = localStorage.getItem("spoc_id");
            let ids = [];
            let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
            axios
                .get(url, {
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                    for (let i = 0; i < response.data.spocs.length; i++) {
                        const element = response.data.spocs[i];
                        ids.push(element.id);
                    }
                    this.gm_ids = ids
                    this.user_with_gms = []
                    this.gm_ids.forEach(gm => {
                        res.forEach(ele => {
                            if (ele.id == gm) {
                                this.user_with_gms.push(ele);
                            }
                        });
                    });
                    this.getDropdownData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getDropdownData() {
            this.treeDataGM[0].children = []
            this.treeDataCity[0].children = []
            this.treeDataSpoc[0].children = []
            let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
            let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
            uniqe_city.forEach(element => {
                let obj = {
                    id: element,
                    label: element,
                    children: []
                }
                if(city_options.includes(element)){
                    this.treeDataCity[0].children.push(obj);
                }
            })
            this.user_with_gms.forEach((element) => {
                this.treeDataCity[0].children.forEach((city) => {
                    if (element.city === city.label) {
                        let cc_set = new Set(city.children.map((item) => item.label));
                        if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
                            let cc_obj = {
                                id: element.city + "_" + element.city_classification,
                                label: element.city_classification
                            }
                            city.children.push(cc_obj);
                            let team = localStorage.getItem('team')
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedCity.push(cc_obj)
                            }
                            this.default_selected_city = this.initSelec
                        }
                    }
                })
            });
            uniqe_city.forEach(element => {
                let obj = {
                    id: element,
                    label: element,
                    children: []
                }
                if (element !== 'Trash') {
                    this.treeDataGM[0].children.push(obj);
                }
            })
            this.user_with_gms.forEach((element) => {
                this.treeDataGM[0].children.forEach((city) => {
                    if (element.city === city.label) {
                        if (element.gm !== "No GM") {
                            let gm_set = new Set(city.children.map((item) => item.label));
                            if (!gm_set.has(element.gm)) {
                                let gm_obj = {
                                    id: element.city + "_" + element.gm,
                                    label: element.gm
                                }
                                city.children.push(gm_obj);
                                let team = localStorage.getItem('team')
                                if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                    this.initSelectedGM.push(gm_obj)
                                }
                                this.default_selected_gm = this.initSelectedGM
                            }
                        }
                    }
                })
            });
            uniqe_city.forEach(element => {
                let obj = {
                    id: element,
                    label: element,
                    children: []
                }
                if (element !== 'Trash') {
                    this.treeDataSpoc[0].children.push(obj);
                }
            })
            this.user_with_gms.forEach((element) => {
                this.treeDataSpoc[0].children.forEach((city) => {
                    if (element.city === city.label) {
                        let gm_set = new Set(city.children.map((item) => item.label));
                        if (!gm_set.has(element.last_name)) {
                            let spoc_obj = {
                                id: element.city + "_" + element.first_name + " " + element.last_name,
                                label: element.first_name + " " + element.last_name
                            }
                            city.children.push(spoc_obj);
                            let team = localStorage.getItem('team')
                            if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                                this.initSelectedSpoc.push(spoc_obj)
                            }
                            this.default_selected_spoc = this.initSelectedSpoc
                        }
                    }
                })
            });
            this.getSpocIds()
        },
        getSpocIds() {
            this.table_data_showing = false;
            let city = [];
            let cc = [];
            let uniqe_city = [];
            let uniqe_gm = [];
            let uniqe_spoc = [];
            if (this.initSelectedCity.length != 0) {
                this.initSelectedCity.forEach(item => {
                    if (item.hasOwnProperty('children')) {
                        city.push(item.id);
                        item.children.forEach(CC => {
                            cc.push(CC.label)
                        })
                    }
                    else if (!item.hasOwnProperty('children')) {
                        city.push(item.id);
                        cc.push(item.label)
                    }
                });
                uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
                uniqe_city = [...new Set(uniqe_city.map((item) => item))];
            }
            if (this.initSelectedGM.length != 0) {
                this.initSelectedGM.forEach(item => {
                    if (item.hasOwnProperty('children')) {
                        city.push(item.id);
                        item.children.forEach(data => {
                            uniqe_gm.push(data.label)
                        })
                    }
                    else if (!item.hasOwnProperty('children')) {
                        city.push(item.id);
                        uniqe_gm.push(item.label)
                    }
                });
                uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
                uniqe_city = [...new Set(uniqe_city.map((item) => item))];
            }
            if (this.initSelectedSpoc.length != 0) {
                this.initSelectedSpoc.forEach(item => {
                    if (item.hasOwnProperty('children')) {
                        city.push(item.id);
                        item.children.forEach(data => {
                            uniqe_spoc.push(data.label)
                        })
                    }
                    else if (!item.hasOwnProperty('children')) {
                        city.push(item.id);
                        uniqe_spoc.push(item.label)
                    }
                });
                uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
                uniqe_city = [...new Set(uniqe_city.map((item) => item))];
            }
            let spoc_ids = [];
            this.user_with_gms.forEach(raw => {
                if (this.initSelectedCity.length != 0) {
                    uniqe_city.forEach(city => {
                        cc.forEach(cc => {
                            if (raw.city == city && raw.city_classification == cc) {
                                spoc_ids.push(raw.id);
                            }
                        })
                    })
                } if (this.initSelectedGM.length != 0) {
                    uniqe_city.forEach(city => {
                        uniqe_gm.forEach(gm => {
                            if (raw.city == city && raw.gm == gm) {
                                spoc_ids.push(raw.id);
                            }
                        })
                    })
                }
                if (this.initSelectedSpoc.length != 0) {
                    uniqe_city.forEach(city => {
                        uniqe_spoc.forEach(spoc => {
                            if (raw.city == city && raw.first_name + " " + raw.last_name == spoc) {
                                spoc_ids.push(raw.id);
                            }
                        })
                    })
                }
            });
            // if (this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0 && this.init_selected_team != 0) {
            //     this.treeDataSpoc[0].children.forEach(child => {
            //         child.children.forEach(spoc => {
            //             this.user_with_gms.forEach(raw => {
            //                 if (raw.first_name + " " + raw.last_name == spoc.label) {
            //                     spoc_ids.push(raw.id);
            //                 }
            //             });
            //         });
            //     });
            // }
            spoc_ids = [...new Set(spoc_ids.map((data) => data))];
            this.filter_spoc_ids = spoc_ids.join()
            this.getAlumniSpocs()
        },
        mergeAllSearch() {
            this.selectedLevels = null;
            this.selectedLevels = this.selected_level

            this.selected_city = [];
            this.initSelectedCity.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataCity[0].children.forEach((child) => {
                        this.selected_city.push(child.label);
                    });
                } else {
                    this.selected_city.push(sort.label);
                }
            });
            this.selected_gm = [];
            this.initSelectedGM.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataGM[0].children.forEach((child) => {
                        this.selected_gm.push(child.label);
                    });
                } else {
                    this.selected_gm.push(sort.label);
                }
            });
            this.selected_spoc = [];
            this.initSelectedSpoc.forEach((sort) => {
                if (sort.label === "All") {
                    this.treeDataSpoc[0].children.forEach((child) => {
                        this.selected_spoc.push(child.label);
                    });
                } else {
                    this.selected_spoc.push(sort.label);
                }
            });
            this.selected_cm = [];
            this.initSelectedCMLevel.forEach((sort) => {
                if (sort === "All") {
                    this.treeDataCMLevel[0].children[0].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                    this.treeDataCMLevel[0].children[1].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                    this.treeDataCMLevel[0].children[2].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                    this.treeDataCMLevel[0].children[3].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                    this.treeDataCMLevel[0].children[4].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                } else if (sort === "P1") {
                    this.treeDataCMLevel[0].children[0].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                } else if (sort === "P2") {
                    this.treeDataCMLevel[0].children[1].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                } else if (sort === "P3") {
                    this.treeDataCMLevel[0].children[2].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                } else if (sort === "ENROLLED") {
                    this.treeDataCMLevel[0].children[3].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                }
                else if (sort === "Dnd") {
                    this.treeDataCMLevel[0].children[4].children.forEach((child) => {
                        this.selected_cm.push(child.label);
                    });
                } else {
                    this.selected_cm.push(sort);
                }
            });
            this.selectedMAccLevels = [];
            this.init_selected_iiml_levels.forEach((sort) => {
                if (sort === "All") {
                    this.macc_levels[0].children.forEach((child) => {
                        this.selectedMAccLevels.push(child.label);
                    });
                } else {
                    this.selectedMAccLevels.push(sort);
                }
            });
        },
        removechip(chip, name) {
            if (name == 'level') {
                this.selected_level = null,
                    this.selectedLevels = null
            }
            if (name == "city") {
                for (let i = 0; i < this.initSelectedCity.length; i++) {
                    if (this.initSelectedCity[i].label === chip) {
                        this.initSelectedCity.splice(i, 1); // Remove the matching object
                        this.selected_city.splice(i, 1);
                        break; // Exit the loop after removing the first match
                    }
                }
            } else if (name == "gm") {
                for (let i = 0; i < this.initSelectedGM.length; i++) {
                    if (this.initSelectedGM[i].label === chip) {
                        this.initSelectedGM.splice(i, 1);
                        this.selected_gm.splice(i, 1);
                        break;
                    }
                }
            } else if (name == "spoc") {
                for (let i = 0; i < this.initSelectedSpoc.length; i++) {
                    if (this.initSelectedSpoc[i].label === chip) {
                        this.initSelectedSpoc.splice(i, 1);
                        this.selected_spoc.splice(i, 1);
                        break;
                    }
                }
            } else if (name == "cm_level") {
                if (this.initSelectedCMLevel.indexOf(chip) === -1) {
                    this.initSelectedCMLevel = [];
                    this.treeDataCMLevel[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.initSelectedCMLevel.push(child.label);
                            this.selected_cm.push(child.label);
                        }
                    });
                } else {
                    this.initSelectedCMLevel.splice(this.initSelectedCMLevel.indexOf(chip), 1);
                    this.selected_cm.splice(this.selected_cm.indexOf(chip), 1);
                }
            } else if (name == "Mlevel") {
                this.selectedMAccLevels = [];
                if (this.init_selected_iiml_levels.indexOf(chip) === -1) {
                    this.init_selected_iiml_levels = [];
                    this.macc_levels[0].children.forEach((child) => {
                        if (child.label !== chip) {
                            this.init_selected_iiml_levels.push(child.label);
                            this.selectedMAccLevels.push(child.label);
                        }
                    });
                } else {
                    this.init_selected_iiml_levels.splice(this.init_selected_iiml_levels.indexOf(chip), 1);
                    this.selectedMAccLevels.splice(
                        this.selectedMAccLevels.indexOf(chip),
                        1
                    );
                }
            }
        },
        clearDateFilter() {
            this.lastCallDateRange = {
                startDate: null,
                endDate: null,
            };
            this.dates = null;
            this.nextCallDateRange = {
                startDate: null,
                endDate: null,
            };
            this.enrollmentDateRange = {
                startDate: null,
                endDate: null,
            };
            this.scheduledDateRange = {
                startDate: null,
                endDate: null,
            }
        },
        refreshdata() {
            this.identity = "";
            this.email = "";
            this.candidate_number = "";
            this.company = "";
            this.candidate_name = "";
            this.experience = "";
            this.designation = "";
            this.count_leads = 0;
            this.selected_level = ['u_level'];
            this.initSelectedCity = [];
            this.selected_city = [];
            this.initSelectedGM = [];
            this.selected_gm = [];
            this.initSelectedSpoc = [];
            this.selected_spoc = [];
            this.initSelectedCMLevel = [];
            this.init_selected_iiml_levels = [];
            this.clearDateFilter();
            this.getUsersWithGMs();
        },
        customFormatter(selected_date) {
            return moment(selected_date).format("MMM-YYYY");
        },
        showTreeBox() {
            return this.treeDataCity
        }
    },
};
</script>
  
<style lang="scss">
.my_date_picker>div input {
    width: 95%;
}

.my_date_picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 44.47px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
}

.a-iconn {
    color: #000;
    font-weight: 400;
    font-size: 12px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
}

.a-iconn>.material-icons {
    margin-left: auto;
}

.ele-inside-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.ele-inside-text>i {
    color: rgba(0, 0, 0, 0.4);
}

.ele-side-head {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
} 
.date-side-head {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-top: 80%;
}

.no-padd {
    padding: 0% !important;
}

.filter-card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.desc-border-box2 p {
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    padding-inline: 20px;
    align-items: center;
    background-color: #0044ba;
    border-radius: 0 0 15px 15px;
    width: 20%;
    height: 35px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.gap {
    margin-bottom: 4%;
}


.vs-dropdown--menu {
    width: 300px;
}

.a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

i {
    font-size: 18px;
}

.filter {
    margin-left: 4px;
}

.alumni-drop {
    margin-top: 0.8%;
}

.alumni-drop#city>.vs-con-dropdown {
    background: #eae5fa;
    border: 1px solid #a697d4;
}

.alumni-drop#spoc>.vs-con-dropdown {
    background: #c3edfa;
    border: 1px solid #6cc8e6;
}

.alumni-drop#lvl>.vs-con-dropdown {
    background: rgb(250, 225, 254);
    border: 1px solid #de56f4;
    border-radius: 10px;
}

.alumni-drop#macc_level_drop>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
}

.alumni-drop#gm>.vs-con-dropdown {
    background: #d5fff5;
    border: 1px solid #35d6af;
    border-radius: 10px;
}

.alumni-drop>.vs-con-dropdown {
    width: 90%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
}


.alumni-drop>div>.reportrange-text {
    background: #FFFBD5 !important;
    border: 1px solid #E4D650 !important;
    width: 94% !important;
    margin-left: 4%;
    border-radius: 10px;
}

.tree-drop#eligible>.vs-con-dropdown {
    background: #FFD5D5;
    border: 1px solid #EE7B7B;
    border-radius: 10px;
}

.none_padding {
    padding: 0px;
}

.desc-border-box p {
    display: flex;
    justify-content: space-between;
    padding-inline: 22.5px;
    align-items: center;
    background-color: #0044ba;
    border-radius: 15px 0px;
    width: 233.62px;
    height: 37.8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.visitor>.reportrange-text {
    background: #ffffff;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
    min-height: 44.47px;
    border-radius: 10px;
}

.date_filter_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.meet_search_criteria {
    width: 135%;
    background: #ffffff;
    min-height: 175px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin-top: 4%;
}

.search_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.meet_criteria_chip {
    border-radius: 5px;
    font-size: 8px;
}

.meet_criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
    font-size: 10px;
}

.search-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100%;
    min-height: 450.69px;
}

.rwd-table {
    width: 98%;
    font-family: 'Montserrat';
    margin: 25px auto;
    border-collapse: collapse;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10),
        0px 10px 20px rgba(0, 0, 0, 0.05),
        0px 20px 20px rgba(0, 0, 0, 0.05),
        0px 30px 20px rgba(0, 0, 0, 0.05);

    tr {
        &:hover {
            background: #f4f4f4;

            td {
                color: #555;
            }
        }
    }

    th,
    td {
        color: #000;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
        border-left: 1px solid rgba(0, 0, 0, 0.25);
        padding: 5px 14px;
        border-collapse: collapse;
    }

    th:first-child,
    td:first-child {
        border-left: 0px solid transparent !important;
    }

    th {
        background: #0044ba;
        color: #fff;
        font-size: 12px;

        &.last {
            border-right: none;
        }
    }

    tr:first-child th:first-child {
        border-top-left-radius: 10px;
    }

    tr:last-child th:last-child {
        border-top-right-radius: 10px;
    }

    tr:first-child td:last-child {
        border-top-right-radius: 10px;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
    }
}

.rwd-table {
    tr td:last-child {
        font-size: 12px;
    }
}

.table-overflow {
    overflow-x: scroll;
    margin-right: 15px !important;
}

.no-data-found {
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: 'Montserrat';
    letter-spacing: 0.3px;
    font-size: 20px;
    font-weight: 900;
    margin-top: 25px
}

.usp-options_filter {
    min-height: 402.41px;
    margin: 2px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.usp-options_filter .vx-card__collapsible-content .vx-card__body {
  padding: 5px 20px !important;
}

.usp-option-side {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.usp-input-filter {
    width: 100% !important;
}

.usp-input-filter.vs-con-input .input-span-placeholder {
    font-size: 14px;
    margin-left: 1%;
}

.usp-input-filter>.vs-con-input>.vs-inputx {
    height: 40px;
    border: 1px solid #0044ba !important;
    border-radius: 15px;
}

@media only screen and (min-width: 1800px) {
    .filter {
        margin-left: 2.8%;
    }
}

@media only screen and (min-width: 1725px) {
    .filter {
        margin-left: 1.4%;
    }
}
</style>