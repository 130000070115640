<template>
    <div>
        <vx-card class="tabulator_card">
            <vs-row class="mb-3">
                <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start">
                    <treeselect v-model="selected_column" :multiple="true" :options="treeDataHide"
                        placeholder="Hide Column" />
                </vs-col>
                <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="center">
                    <vs-button color="#0044BA" size="small" @click="HideColumn">
                        Hide - Unhide Columns
                    </vs-button>
                </vs-col>
            </vs-row>
            <div ref="table2" id="example-table-theme"></div>
        </vx-card>
    </div>
</template>
  
<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
export default {
    components: {
        Treeselect,
    },
    props: ["alumni_table_details"],
    mounted() {
        console.log("tableData_Alumni", this.alumni_table_details);
        this.alumni_details = this.alumni_table_details;
        this.getAlumniCandidateDetails();
        for (let i = 0; i < this.alumni_details.length; i++) {
            const element = this.alumni_details[i];
            if (element.enrollment_date !== null) {
                element.enrollment_date = moment
                    .unix(element.enrollment_date)
                    .format("DD-MMM-YYYY");
            }
            if (element.created_at !== null) {
                element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
            }
            if (element.last_call !== null) {
                element.last_call = moment
                    .unix(element.last_call)
                    .format("DD-MMM-YYYY");
            }
            if (element.next_call !== null) {
                element.next_call = moment
                    .unix(element.next_call)
                    .format("DD-MMM-YYYY");
            }
            if (element.mhp_date !== null) {
                element.mhp_date = moment.unix(element.mhp_date).format("DD-MMM-YYYY");
            }
            Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
        }
    },
    data() {
        return {
            alumni_details: [],
            selected_column: [],
            treeDataHide: [
                {
                    id: "identity",
                    label: "Candidate ID"
                }, {
                    id: "person_name",
                    label: "Candidate Name"
                }, {
                    id: "company",
                    label: "Company"
                },
                {
                    id: "experience_int",
                    label: "Experience"
                },
                {
                    id: "education",
                    label: "Education"
                },
                {
                    id: "level",
                    label: "M Level"
                },
                {
                    id: "iiml_level",
                    label: "U Level"
                },
                {
                    id: "enrollment_date",
                    label: "Enrollment Date"
                },
                {
                    id: "courses",
                    label: "Course"
                },
                {
                    id: "spoc_name",
                    label: "CM SPOC"
                },
                {
                    id: "spoc_city",
                    label: "SPOC City"
                },
                {
                    id: "gm_name",
                    label: "GM"
                },
                {
                    id: "sr_name",
                    label: "SR SPOC"
                },
                {
                    id: "created_at",
                    label: "Lead Created Date"
                },
                {
                    id: "last_call",
                    label: "Last Call"
                },
                {
                    id: "next_call",
                    label: "Next Call"
                },
                {
                    id: "fam_lam.first_source",
                    label: "FAM"
                },
                {
                    id: "fam_lam.last_source",
                    label: "LAM"
                },
                {
                    id: "mhp_date",
                    label: "MHP Date"
                },
                {
                    id: "info",
                    label: "View"
                },

            ],
        };
    },
    watch: {
        alumni_table_details(val) {
            this.alumni_details = val;
            this.getAlumniCandidateDetails();
            for (let i = 0; i < this.alumni_details.length; i++) {
                const element = this.alumni_details[i];
                if (element.enrollment_date !== null) {
                    element.enrollment_date = moment
                        .unix(element.enrollment_date)
                        .format("DD-MMM-YYYY");
                }
                if (element.created_at !== null) {
                    element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
                }
                if (element.last_call !== null) {
                    element.last_call = moment
                        .unix(element.last_call)
                        .format("DD-MMM-YYYY");
                }
                if (element.next_call !== null) {
                    element.next_call = moment
                        .unix(element.next_call)
                        .format("DD-MMM-YYYY");
                }
                if (element.mhp_date !== null) {
                    element.mhp_date = moment
                        .unix(element.mhp_date)
                        .format("DD-MMM-YYYY");
                }
                Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
            }
        },
    },
    methods: {
        getAlumniCandidateDetails() {
            this.tabulator = new Tabulator(this.$refs.table2, {
                maxHeight: "80vh",
                data: this.alumni_details,
                rowFormatter: (function(row) {
                var evenColor = "#F5F6FA";
                var oddColor = "#FFF";
                row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
                }),
                columns: [

                    {
                        title: "Candidate ID",
                        field: "identity",
                        frozen: true,
                        sorter: "alphanum",
                    },
                    {
                        title: "Candidate Name",
                        field: "person_name",
                        frozen: true,
                    },
                    {
                        title: "Company",
                        field: "company",
                    },
                    {
                        title: "Experience",
                        field: "experience_int",
                    },
                    {
                        title: "Education",
                        field: "education",
                    },
                    {
                        title: "M Level",
                        field: "level",
                        sorter: "alphanum",
                    },
                    {
                        title: "U Level",
                        field: "iiml_level",
                        sorter: "alphanum",
                    },
                    {
                        title: "Enrollment Date",
                        field: "enrollment_date",
                        headerSort: false,
                    },
                    {
                        title: "Course",
                        field: "courses",
                    },
                    {
                        title: "CM SPOC",
                        field: "spoc_name",
                    },
                    {
                        title: "SPOC City",
                        field: "spoc_city",
                    },
                    {
                        title: "GM",
                        field: "gm_name",
                    },
                    {
                        title: "SR SPOC",
                        field: "sr_name",
                    },
                    {
                        field: "created_at",
                        title: "Lead Created Date"
                    },
                    {
                        title: "Last Call",
                        field: "last_call",
                        headerSort: false,
                    },
                    {
                        title: "Next Call",
                        field: "next_call",
                        headerSort: false,
                    },
                    {
                        title: "FAM",
                        field: "fam_lam.first_source",
                        headerSort: false,
                    },
                    {
                        title: "LAM",
                        field: "fam_lam.last_source",
                        headerSort: false,
                    },
                    {
                        title: "MHP Date",
                        field: "mhp_date",
                        headerSort: false,
                    },
                    {
                        title: "About",
                        field: "info",
                        formatter: "image",
                        hozAlign: "center",
                        frozen: true,
                        headerSort: false,
                        formatterParams: {
                            height: "25px",
                            width: "35px",
                        },
                        cssClass: "visit_about_cell",
                        cellClick: this.getId,
                    },

                ],
            });
        },
        getId(e, cell) {
            console.log(e, cell._cell.row.data.id);
            this.openBigPopup(cell._cell.row.data.id);
        },
        HideColumn() {
            this.treeDataHide.forEach((treeData) => {
                this.tabulator.showColumn(treeData.id)
            });
            if (this.selected_column.length > 0) {
                this.selected_column.forEach((selected_item) => {
                    this.treeDataHide.forEach((item) => {
                        if (item.id == selected_item) {
                            this.tabulator.hideColumn(item.id)
                        }
                    })
                })
            }
        },
    },
};
</script>
  
<style>
.tabulator_card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.tabulator {
    background-color: #f2f2f2;
}

.tabulator .tabulator-header {
    background-color: #f2f2f2;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
    background-color: #f2f2f2;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
    background-color: #e4e1e1;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
    text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
    background-color: rgb(0, 0, 0);
    color: #f2f2f2 !important;
}

.visit_about_cell img {
    padding-inline-start: 10px;
}
</style>